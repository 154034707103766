import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state() {
    return {
      offer: null,
    }
  },
  mutations: {
    setOffer(state, data) {
      state.offer = data
    },
  },
  actions: {
    async fetchOffer({ commit, dispatch }) {
      try {
        const {data} = await axios.get('/api/offer')
        
        if( data?.offerText ) {
          commit('setOffer', data.offerText)
        } else {
          throw new Error(); 
        }

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
  },
  getters: {
    offer(state) {
      return state.offer
    },
  }
}