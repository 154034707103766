import en from "@/locales/en.js";
import ru from "@/locales/ru.js";
import uz from "@/locales/uz.js";

const languages = [
  {
    label: 'Русский',
    locale: 'ru',
    file: ru
  },
  {
    label: 'English',
    locale: 'en',
    file: en
  },
  {
    label: "O'zbek",
    locale: 'uz',
    file: uz
  },
]

export default languages;