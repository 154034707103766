<script setup>
import Cookie from "js-cookie";
import { useI18n } from 'vue-i18n'
import i18n from "@/plugins/i18n";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { LanguageIcon } from '@heroicons/vue/24/outline'
import { useRoute, useRouter } from 'vue-router';
import languages from "@/utils/languages";
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();
const store = useStore();
const { locale } = useI18n();


const languagesObject = reactive(languages.reduce((obj, lang) => {
  obj[lang.locale] = lang.label;
  return obj;
}, {}));

const langList = computed(() => {
  return i18n.global.availableLocales
  
})

const changeLanguage = (lang, closeMethod) => {
  locale.value = lang;
  store.dispatch('setLocale', lang)
  Cookie.set('locale', lang, { expires: 7, secure: true, sameSite: 'Strict' });
  const selectedLang = lang === 'ru' ? '' : `/${lang}`
  const newPath = `${selectedLang}${route.path.replace(/^\/(en|uz)\//, '/')}`;
  closeMethod()
  router.push(newPath)
} 

</script>

<template>
  <Menu as="div" v-slot="{ close }" class="relative inline-block text-left leading-none">
    <MenuButton class="inline-flex w-full justify-center gap-x-1.5 text-sm font-semibold text-black">
      <span class="hidden sm:block">{{ languagesObject[locale] }}</span>
      <LanguageIcon class="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
    </MenuButton>
  
    <transition
      enter-active-class="transition ease-out duration-100" 
      enter-from-class="transform opacity-0 scale-95" 
      enter-to-class="transform opacity-100 scale-100" 
      leave-active-class="transition ease-in duration-75" 
      leave-from-class="transform opacity-100 scale-100" 
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="absolute right-0 z-10 mt-2 w-24 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-for="lang in langList" :key="`locale-${lang}`" :value="lang">
            <a
              href="#"
              @click.prevent="changeLanguage(lang, close)"
              class="text-gray-700 block px-4 py-2 text-sm"
              :class="[{'text-green-jet': lang === locale}]"
            >
              {{ languagesObject[lang] }}
            </a>
          </MenuItem>

        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

