import { createStore, createLogger } from 'vuex'
import account from './modules/account.module'
import contract from './modules/contract.module'
import service from './modules/service.module'
import jetPoints from './modules/jetPoints.module'
import tariffs from './modules/tariffs.module'
import history from './modules/history.module'
import offer from './modules/offer.module'
import notifications from './modules/notifications.module'

const plugins = []

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}

export default createStore({
  plugins,
  state() {
    return {
      loading: null,
      message: null,
      messageTimeoutId: null,
      redirectPath: null,
      locale: 'uz'
    }
  },
  mutations: {
    setMessage(state, message) {
      state.message = message
    },
    clearMessage(state) {
      state.message = null
    },
    setMessageTimeoutId(state, id) {
      state.messageTimeoutId = id;
    },
    setRedirectPath(state, path) {
      state.redirectPath = path
    },
    setLocale(state, locale) {
      state.locale = locale
    },
    setLoading(state) {
      state.loading = true
    },
    clearLoading(state) {
      state.loading = false
    }
  },
  actions: {
    setLocale({commit}, locale) {
      commit('setLocale', locale)
    },
    setMessage({commit, state}, message) {
      commit('setMessage', message)

      if (state.messageTimeoutId !== null) {
        clearTimeout(state.messageTimeoutId);
      }
      const timeouteId = setTimeout(() => {
        commit('clearMessage')
      }, 5000)
      
      commit('setMessageTimeoutId', timeouteId)
      
    }
  },
  getters: { 
    redirectPath(state) {
      return state.redirectPath
    },
    locale(state) {
      return state.locale
    },
  },
  modules: {
    account, 
    contract,
    service,
    jetPoints,
    tariffs,
    history,
    offer,
    notifications,
  }
})
