<script setup>
import { onMounted } from "vue";
import { RouterView, useRoute } from "vue-router";
import Header from "@/components/AppHeader.vue";
import Footer from "@/components/AppFooter.vue";
import AppNotification from "@/components/AppNotification.vue";
import AppLoader from "@/components/AppLoader.vue";
const route = useRoute()
onMounted(() => {
	document.body.classList.add('bg-slate-40')
})
</script>

<template>
	<div class="layout flex flex-col min-h-screen font-manrope">
		
    <Header />
		
		<div class="layout__content flex flex-col grow pt-10 pb-8 relative">

			<div class="container flex flex-col mx-auto max-w-7xl w-full px-6 lg:px-8 grow">

        <AppNotification />

				<RouterView />

        <AppLoader />

			</div>

		</div>

		<Footer class="mt-auto" />

	</div>
</template>

<style lang="scss">
.noise-overlay {
  opacity: 0.03;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://i.gifer.com/Xi06.gif"); 
  background-size: 100px auto; 
  background-repeat: repeat; 
  pointer-events: none;
  z-index: 999;
}
</style>
