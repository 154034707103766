import axios from "@/plugins/axios"

export default {
  namespaced: true,
  state() {
    return {
      notifications: null,
    }
  },
  mutations: {
    setNotifications(state, data) {
      state.notifications = data
    },
  },
  actions: {
    async fetchNotifications({ commit, dispatch }) {
      try {
        const {data} = await axios.get('/api/notifications')
        
        if( data?.data ) {
          commit('setNotifications', data.data)
        } else {
          throw new Error(); 
        }

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
  },
  getters: {
    notifications(state) {
      return state.notifications
    },
  }
}