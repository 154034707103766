
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const isLoading = computed(() => store.state.loading)

</script>

<template>
  <transition
    leave-active-class="transition ease-in duration-100" 
    leave-from-class="opacity-100" 
    leave-to-class="opacity-0"
  >
    <div
      v-if="isLoading"
      class="fixed top-0 left-0 w-full h-full bg-slate-40 flex flex-col items-center justify-center z-50"
    >
    <div class="w-12 h-12 rounded-full animate-spin border-y-4 border-solid border-green-jet border-t-transparent shadow-md"></div>
    <div class="logo mt-8">
      <img class="w-28 sm:w-32" src="@/assets/images/logo-jet.svg" alt="">
    </div>
    <div class="noise-overlay">&nbsp;</div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>

</style>