
export default {
  namespaced: true,
  state() {
    return {
      contract: null,
    }
  },
  mutations: {
    setContract(state, contract) {
      state.contract = contract
    },
    clearContract(state) {
      state.contract = null
    },
  },
  actions: {
  },
  getters: {
    contract(state) {
      return state.contract
    },
  }
}