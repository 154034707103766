import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state() {
    return {
      history: null,
    }
  },
  mutations: {
    setHistory(state, history) {
      state.history = history
    },
    clearHistory(state) {
      state.history = null
    },
  },
  actions: {
    async fetchHistory({commit}, { acc_id, form_data }) {
      try {
        const {data} = await axios.post(`/api/payment-history/${acc_id}`, {...form_data})
        commit('setHistory', data?.original?.pays?.pay || null)
      } catch(e) {
      }
    }
  },
  getters: {
    history(state) {
      return state.history
    },
  }
}