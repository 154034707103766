
export default {
  namespaced: true,
  state() {
    return {
      jetPoints: null,
    }
  },
  mutations: {
    setTariffsTurbo(state, jetPoints) {
      state.jetPoints = jetPoints
    },
    clearJetPoints(state) {
      state.jetPoints = null
    },
  },
  actions: {
  },
  getters: {
    jetPoints(state) {
      return state.jetPoints
    },
  }
}